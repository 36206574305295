import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./assets/css/modal.css";
import "./assets/css/swiper-custom.css";
import "./assets/css/logo.css";

import DynamicPage from "./DesginComponent/DynamicPage";
import Header from "./components/Header";
import Banner1 from "./components/banners/Banner1";
import Banner2 from "./components/banners/Banner2";
import Banner5 from "./components/banners/Banner5";
import ServicesBar from "./components/ServicesBar";
import Footer from "./components/Footer";
import NewsLetter from "./components/NewsLetter";
import BlogList from "./components/blog/BlogList";
import About from "./components/About";
import SingleBlog from "./components/SingleBlog";
import ContactBanner from "./components/contact/ContactBanner";
import ContactForm from "./components/contact/ContactForm";
import Faq1 from "./components/Faq1";
import Faq2 from "./components/Faq2";
import NewsBar from "./components/NewsBar";
import LogoBar1 from "./components/LogoBar1";
import LogoBar2 from "./components/LogoBar2";
import BlogSlider from "./components/blog/BlogSlider";
import BlogBanner from "./components/blog/BlogBanner";
import AboutBanner from "./components/AboutBanner";
import AboutUsSection from "./components/AboutUsSection";
import MiddleSection1 from "./components/MiddleSection1";
import MiddleSection2 from "./components/MiddleSection2";
import MiddleService from "./components/MiddleService";
import TradingViewWidget from "./components/TradingViewWidget";

const componentMap = {
  Header,
  Banner1,
  Banner2,
  Banner5,
  ServicesBar,
  Footer,
  NewsLetter,
  BlogList,
  About,
  SingleBlog,
  ContactForm,
  BlogBanner,
  ContactBanner,
  Faq1,
  Faq2,
  NewsBar,
  LogoBar1,
  LogoBar2,
  AboutBanner,
  AboutUsSection,
  MiddleSection1,
  MiddleSection2,
  MiddleService,
  TradingViewWidget
};

const App = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathSegments = pathname.split("/").filter(Boolean);

  useEffect(() => {
    document.body.setAttribute(
      "dir",
      localStorage.getItem("i18nextLng") == "ar" ? "rtl" : "ltr"
    );
  }, []);

  useEffect(() => {
    const cssUrl = process.env.REACT_APP_API_URL;
    const websiteId = process.env.REACT_APP_WEBSITE_ID;

    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = `${cssUrl}/css/${websiteId}.css`;
    document.head.appendChild(link);

    let isAr = localStorage.getItem("i18nextLng") == "ar";

    let style = document.createElement("link");
    style.rel = "stylesheet";
    style.href = `${window.location.origin}/assets/css/style${isAr ? "-rtl" : ""}.css`;

    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div>
      <DynamicPage
        componentMap={componentMap}
        pageName={pathSegments[0] || "home"}
        pageId={pathSegments[1] || null}
      />
    </div>
  );
};

export default App;
